import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { _getInitMicroAPP } from "@/assets/js/globalUtils.js";
import login from "@/views/login/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/micro-platform",
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "*",
    component: () => import("@/views/container.vue"),
    children: [
      {
        path: "404",
        name: "notFound",
        component: () => import("@/views/404/Index.vue"),
      },
      {
        path: "notAuthority",
        name: "notAuthority",
        component: () => import("@/views/noAuth/Index.vue"),
      },
    ],
  },
];
VueRouter.prototype.push = (() => {
  const pushFn = VueRouter.prototype.push;
  return function (location) {
    return pushFn.call(this, location).catch((er) => er);
  };
})();

VueRouter.prototype.replace = (() => {
  const replaceFn = VueRouter.prototype.replace;
  return function (location) {
    return replaceFn.call(this, location).catch((er) => er);
  };
})();
const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});

export default router;
