/*
 * @Author: your name
 * @Date: 2021-11-23 09:09:31
 * @LastEditTime: 2021-11-24 15:23:15
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sso_login\src\staff\utils\rulesConfig.js
 */
const rulesConfig = {
  input: [
    { validator: "notBlank" },
    { min: 1, max: 50, message: "长度在1到50个字符" }
  ],
  textarea: [
    { validator: "notBlank" },
    { min: 1, max: 400, message: "长度在1到400个字符" }
  ],
  notBlank: [{ validator: "notBlank" }],
  posInteger: [{ validator: ["integer", "pos"] }],
  mobile: [
    { required: true, message: "请输入手机号码" },
    { validator: "mobile" }
  ]
};

export default rulesConfig;
