import * as BASES from "../qiankun/base";
import { _completelyMicroApps } from "@/assets/js/globalUtils.js";

const originMicroApps = [
  {
    name: "platform",
    systemCode: "sys_platform",
    label: "平台",
    icon: "icon-main-pingtai",
    path: "",
    sysId: 0,
  },
  {
    name: "market",
    systemCode: "sys_market",
    label: "销售系统",
    icon: "icon-main-xiaoshouxitong",
    path: "",
    sysId: 1,
  },
  {
    name: "purchase",
    systemCode: "sys_purchase",
    label: "采购系统",
    icon: "icon-main-a-caigouxitongcaigoucaigoudingdan",
    path: "",
    sysId: 6,
  },
  {
    name: "produce",
    systemCode: "sys_produce",
    label: "生产系统",
    icon: "icon-main-shengchanxitong",
    path: "",
    sysId: 2,
  },
  {
    name: "research",
    systemCode: "sys_research",
    label: "研发系统",
    icon: "icon-main-yanfaxitong",
    path: "",
    sysId: 3,
  },
  {
    name: "quality",
    systemCode: "sys_qms",
    label: "品管系统",
    icon: "icon-main-pinguanxitong",
    path: "",
    sysId: 5,
  },
  {
    name: "store",
    systemCode: "sys_store",
    label: "仓储系统",
    icon: "icon-main-cangchuxitong",
    path: "",
    sysId: 4,
  },
  {
    name: "lims",
    label: "LIMS",
    icon: "icon-main-weicexitong",
    path: "",
    url: "http://www.test4u.cn/login",
    sysId: null,
  },
];

export default _completelyMicroApps(BASES, originMicroApps);
