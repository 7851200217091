import { jsonp } from "vue-jsonp";
import router from "@/router";
import store from "@/store";
export default function () {
  // 获取当前环境(未登陆前拿不到userInfo内的环境信息，只能暴力判断url)
  const DEV = "dev",
    TEST = "test",
    STAGE = "stage",
    MASTER = "master";
  const envDictionary = {
    "pla-dev.hb.com": DEV,
    localhost: DEV,
    "127.0.0.1": DEV,
    "pla-test.hb.com": TEST,
    "stagesass.hbtesaas.com": STAGE,
  };
  const ENV = envDictionary[location.hostname] || MASTER;
  // app配置
  // 需要在钉钉工作台配置各个环境的扫码登录的回调路由，拿到生成的appkey填写到这里
  // 每次修改，后端的redirectUrl接口也需要同时进行修改
  const APP_CONFIG = {
    appId: ((env) => {
      // 老版配置多个扫码登录appKey，不再使用
      // return (
      //   {
      //     stage: "dingoa2d7xeq5e7lujksgg",
      //     master: "dingx4gkhwa8x5rpbczu",
      //   }[env] || "dingx4gkhwa8x5rpbczu"
      // );
      return "dingx4gkhwa8x5rpbczu";
      // return "dingoaik2b9q8jygv8jg3e"; // 本地测试
    })(ENV),

    // 回调地址，
    redirectUrl: (() => {
      return location.origin + "/auth/dingTalkLogin";
    })(),
  };
  const redirectUrl = encodeURIComponent(APP_CONFIG.redirectUrl);
  // 注册钉钉登录
  DDLogin({
    id: "dingTalkQRcode", // 这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
    goto: encodeURIComponent(
      "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" +
        APP_CONFIG.appId +
        "&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" +
        redirectUrl,
    ), // 请参考注释里的方式
    style: "border:none;background-color:#fff",
    width: "220",
    height: "300",
  });

  // 扫码成功后的回调处理
  const handleMessage = function (event) {
    const origin = event.origin;
    if (origin === "https://login.dingtalk.com") {
      // 判断是否来自ddLogin扫码事件。
      const loginTmpCode = event.data; // 拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
      const URL =
        "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" +
        APP_CONFIG.appId +
        "&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" +
        redirectUrl +
        "&loginTmpCode=" +
        loginTmpCode;
      // 请求钉钉的地址，该地址服务会生成code并重定向到redirectUrl带上code参数，而jsonp的回调会接收redirectUrl对应接口的返回值
      // 所以该jsonp请求实际上是处理的redirectUrl接口
      jsonp(URL, {
        code: loginTmpCode,
        callbackName: "callback",
      })
        .then((data) => {
          if (data.code == "200") {
            // data.body.user.userId = data.body.user.userIdStr;
            // store.commit("setUserInfo", data.body.user);
            store.commit("setAuthorization", data.body);
            window.dispatchEvent(new Event("dingTalkLoginSuccess"));
            // store.commit("setEnterprise", true);
          } else {
            alert(data.msg);
          }
        })
        .catch((e) => {
          console.log("dd error", e);
        });
    }
  };
  if (typeof window.addEventListener !== "undefined") {
    window.addEventListener("message", handleMessage, false);
  } else if (typeof window.attachEvent !== "undefined") {
    window.attachEvent("onmessage", handleMessage);
  }
}
