<template>
  <div class="micro-main-app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  methods: {},
};
</script>
<style lang="scss" scoped>
.micro-main-app__container {
  display: flex;
}
.micro-main-app__content {
  flex: 1;
  width: 100%;
  height: calc(100vh - 30px);
  overflow-y: hidden;
}
.hb-nav-menu {
  flex-shrink: 0;
}
.hb-topbar {
  box-sizing: border-box;
}
</style>