import Vuex from "vuex";
import Vue from "vue";
import Router from "../router";
import http from "../request";
import microApps from "../config/microApps";
import { _removeSessionStore } from "@/assets/js/globalUtils.js";
import moment from "moment";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userInfo: {},
    usersList: [], //全员枚举
    levelDeptData: [], //一二三级部门字典数据
    organizationData: [], //组织架构数据
    fullOrganizationData: {}, //含有人员的组织架构数据
    companyList: [], //账套列表
    activeCompany: {}, //当前激活的账套
    andengData: ["红灯", "黄灯", "绿灯"],
    levelData: ["S", "A", "B", "C"],
    fullScreen: false,
    Authorization: "",
    microApps, //微应用系统导航集合，以后会根据系统权限进行过滤
    microRoutes: [
      {
        label: "工作台",
        labelName: "",
        fullPath: "/micro-platform",
        active: true,
      },
    ],
    activeAppLabel: "", //当前激活的微应用中文名
    monthRangeOption: {
      shortcuts: [
        {
          text: "上月",
          onClick(picker) {
            let time = moment().subtract(1, "months").format("yyyy-MM");
            picker.$emit("pick", [time, time]);
          },
        },
        {
          text: "本月",
          onClick(picker) {
            let time = moment().format("YYYY-MM");
            picker.$emit("pick", [time, time]);
          },
        },
        {
          text: "一季度",
          onClick(picker) {
            let year = moment().format("YYYY");
            picker.$emit("pick", [year + "-01", year + "-03"]);
          },
        },
        {
          text: "二季度",
          onClick(picker) {
            let year = moment().format("YYYY");
            picker.$emit("pick", [year + "-04", year + "-06"]);
          },
        },
        {
          text: "三季度",
          onClick(picker) {
            let year = moment().format("YYYY");
            picker.$emit("pick", [year + "-07", year + "-09"]);
          },
        },
        {
          text: "四季度",
          onClick(picker) {
            let year = moment().format("YYYY");
            picker.$emit("pick", [year + "-10", year + "-12"]);
          },
        },
        {
          text: "上半年",
          onClick(picker) {
            let year = moment().format("YYYY");
            picker.$emit("pick", [year + "-01", year + "-06"]);
          },
        },
        {
          text: "下半年",
          onClick(picker) {
            let year = moment().format("YYYY");
            picker.$emit("pick", [year + "-07", year + "-12"]);
          },
        },
        {
          text: "今年",
          onClick(picker) {
            let year = moment().format("YYYY");
            picker.$emit("pick", [year + "-01", year + "-12"]);
          },
        },
      ],
    },
    permissionMemuPaths: [], //当前账号有权限的模块的路由
    cancelToken: [],
  },
  mutations: {
    //核心数据token
    setAuthorization(state, str) {
      let token = str || window.localStorage.getItem("token");
      state.Authorization = "Bearer " + token;
      str && localStorage.setItem("token", str);
    },
    //核心数据账套信息
    setCompanyList(state, arr) {
      state.companyList = arr;
    },
    //核心数据用户数据
    setUserInfo(state, obj) {
      state.userInfo = obj;
      localStorage.setItem("userInfo", JSON.stringify(obj));
    },
    //设置当前选中的企业
    setActiveCompany(state, obj) {
      state.activeCompany = obj;
      localStorage.setItem("activeCompany", JSON.stringify(obj));
    },
    //修改了用户信息立马同步如上传新logo
    updateUserInfo(state, { key, val }) {
      state.userInfo[key] = val;
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    //枚举
    setUsersList(state, arr) {
      state.usersList = arr;
    },
    //枚举
    setOrganizationData(state, arr) {
      state.organizationData = arr;
    },
    //枚举
    setFullOrganizationData(state, obj) {
      state.fullOrganizationData = obj;
    },
    setLevelDeptData(state, arr) {
      state.levelDeptData = arr;
    },
    clearCancelToken(state) {
      state.cancelToken.forEach((e) => e());
      state.cancelToken = [];
    },
    setFullCreen(state, boolean) {
      if (typeof boolean === "boolean") {
        state.fullScreen = boolean;
      } else {
        state.fullScreen = !state.fullScreen;
      }
    },
    setMicroRoutes(state, routes) {
      let defaultRouters = [
        {
          label: "工作台",
          labelName: "",
          fullPath: "/micro-platform",
          active: true,
        },
      ];
      state.microRoutes = routes || defaultRouters;
    },
    setPermissionMemuPaths(state, arr = []) {
      state.permissionMemuPaths = arr;
    },
    pushMicroRoutes(state, route) {
      state.cancelToken.forEach((e) => e());
      state.cancelToken = [];
      state.microRoutes.find((item) => item.active).active = false;
      //判断路由是否允许多开
      if (route.unMutipleSign) {
        let index = state.microRoutes.findIndex(
          (item) => item.unMutipleSign === route.unMutipleSign,
        );
        /**
         * 替换掉同源旧路由（不允许多开的路由要被最新的这个替换掉）
         * fullPath不同则清除缓存,一般是编辑页替换另一编辑页，被替换的编辑页的缓存需要清除
         * 但是新增页到新增页不能清除缓存
         */
        if (index !== -1) {
          route.fullPath != state.microRoutes[index].fullPath &&
            _removeSessionStore(state.microRoutes[index].fullPath);
          state.microRoutes.splice(index, 1, route);
        } else {
          state.microRoutes.push(route);
        }
      } else {
        //判断路由是否已存在
        let target = state.microRoutes.find((item) => item.fullPath === route.fullPath);
        target ? (target.active = true) : state.microRoutes.push(route);
      }
      state.microRoutes = [...state.microRoutes];
      sessionStorage.setItem("microRoutes", JSON.stringify(state.microRoutes));
    },
    removeRoutes(state, { route, type }) {
      //需要缓存storage中
      let index = state.microRoutes.findIndex((item) => item === route);
      let isActive = route.active;
      if (!type) {
        //清除页面数据缓存
        _removeSessionStore(route.fullPath);
        //关闭当前标签页路由
        state.microRoutes.splice(index, 1);
        state.microRoutes = [...state.microRoutes];
        //激活下一个标签页路由或最后一个标签页路由
        if (isActive) {
          if (state.microRoutes.length > index) {
            state.microRoutes[index].active = true;
            Router.push(state.microRoutes[index].fullPath);
          } else {
            state.microRoutes[index - 1].active = true;
            Router.push(state.microRoutes[index - 1].fullPath);
          }
        }
      } else if (type === "right") {
        //关闭右侧标签页路由
        let deleted = state.microRoutes.splice(index + 1);
        deleted.forEach((item) => {
          //清除页面数据缓存
          _removeSessionStore(item.fullPath);
        });
        state.microRoutes = [...state.microRoutes];
        if (!state.microRoutes.some((item) => item.active)) {
          route.active = true;
          Router.push(route.fullPath);
        }
      } else if (type === "left") {
        //关闭左侧标签页路由
        let deleted = state.microRoutes.splice(1, index - 1);
        //清除页面数据缓存
        deleted.forEach((item) => {
          _removeSessionStore(item.fullPath);
        });
        state.microRoutes = [...state.microRoutes];
        if (!state.microRoutes.some((item) => item.active)) {
          route.active = true;
          Router.push(route.fullPath);
        }
      } else if (type === "other") {
        //关闭其它标签页路由
        let deleted = state.microRoutes.splice(1);
        //清除页面数据缓存
        deleted.forEach((item) => {
          if (item.fullPath !== route.fullPath) {
            _removeSessionStore(item.fullPath);
          }
        });
        //避免出现两个工作台标签页
        if (route.fullPath === "/micro-platform") {
          state.microRoutes = [route];
        } else {
          state.microRoutes = [state.microRoutes[0], route];
        }
        if (!isActive) {
          route.active = true;
          Router.push(route.fullPath);
        }
      } else if (type === "all") {
        //关闭所有路由标签
        let deleted = state.microRoutes.splice(1);
        //清除页面数据缓存
        deleted.forEach((item) => {
          _removeSessionStore(item.fullPath);
        });
        state.microRoutes = [...state.microRoutes];
        Router.push(state.microRoutes[0].fullPath);
      }
      sessionStorage.setItem("microRoutes", JSON.stringify(state.microRoutes));
    },
    removeCurrentRoute(state, { isClose = true, fullPath, toPath }) {
      let index = state.microRoutes.findIndex((item) => item.fullPath === fullPath);
      isClose && state.microRoutes.splice(index, 1);
      // 如果是最后一个被删除，index需要-1
      index > state.microRoutes.length - 1 && (index -= 1);
      state.microRoutes[index].active = true;
      state.microRoutes = [...state.microRoutes];
      sessionStorage.setItem("microRoutes", JSON.stringify(state.microRoutes));
      const fullPaths = state.microRoutes.map((item) => item.fullPath);
      if (toPath) {
        Router.replace(toPath);
      } else {
        Router.push(state.microRoutes[index].fullPath);
      }
    },
    replaceRoute(state, { from }) {
      state.microRoutes = state.microRoutes.filter((item) => item.fullPath !== from.fullPath);
      _removeSessionStore(from.fullPath);
    },
  },
  getters: {
    //部门树状枚举
    deptOptions(state) {
      if (state.organizationData[0]) {
        let data = state.organizationData[0].children;
        let index = data.findIndex((item) => item.label === "部门");
        return [data[index]];
      } else {
        return [];
      }
    },
    levelFirstDept(state) {
      if (state.organizationData[0]) {
        let data = state.organizationData[0].children;
        let index = data.findIndex((item) => item.label === "部门");
        let res = data[index].children.map((i) => ({
          label: i.name,
          value: i.id,
        }));
        return res;
      } else {
        return [];
      }
    },
    //产品线枚举
    productLineOptions(state) {
      if (state.organizationData[0]) {
        let data = state.organizationData[0].children;
        let index = data.findIndex((item) => item.label === "产品线");
        return data[index].children;
      } else {
        return [];
      }
    },
    //区域中心枚举
    areaCenterOptions(state) {
      if (state.organizationData[0]) {
        let data = state.organizationData[0].children;
        let index = data.findIndex((item) => item.label === "区域中心");
        return data[index].children;
      } else {
        return [];
      }
    },
    //获取业务字典部门数据，传012获取一二三级部门数据
    levelDeptDataFromDic: (state) => (level) => {
      return state.levelDeptData[level]?.children || [];
    },
    companyOptions(state) {
      return state.companyOptions;
    },
    andengOptions(state) {
      return state.andengData.map((item) => ({
        label: item,
        value: item,
      }));
    },
    levelOptions(state) {
      return state.levelData.map((item) => ({
        label: item,
        value: item,
      }));
    },
  },
  actions: {
    //企业信息
    getCompanyList({ commit, state }, init) {
      return new Promise((resolve, reject) => {
        //优先判断vuex中是否有数据
        if (state.companyList.length && !init) {
          resolve(state.companyList);
          //其次判断localStorage中是否有数据
        } else if (window.localStorage.getItem("companyList") && !init) {
          let companyList = window.localStorage.getItem("companyList");
          try {
            companyList = JSON.parse(companyList);
            commit("setCompanyList", companyList);
            resolve(companyList);
          } catch (error) {
            reject(error);
          }
        } else {
          //都没有请求线上数据
          http.get("/enterpriseUser/findByUser").then((res) => {
            res = res.map((item) => ({
              value: item.enterpriseCode,
              logo: item.enterpriseLog,
              label: item.enterpriseName,
              name: item.name,
              enName: item.enName,
            }));
            commit("setCompanyList", res);
            window.localStorage.setItem("companyList", JSON.stringify(res));
            resolve(res);
          });
        }
      });
    },
    //用户信息
    getUserInfo({ commit, state }, init) {
      return new Promise((resolve, reject) => {
        if (state.userInfo.userId && !init) {
          resolve(state.userInfo);
        } else if (window.localStorage.getItem("userInfo") && !init) {
          let userInfo = window.localStorage.getItem("userInfo");
          try {
            userInfo = JSON.parse(userInfo);
            commit("setUserInfo", userInfo);
            resolve(userInfo);
          } catch (error) {
            reject(error);
          }
        } else {
          http.get("/user/getSysUsersInfo").then((res) => {
            res = { ...res, userId: res.id, logo: res.headImage, depts: res.sysDepts };
            commit("setUserInfo", res);
            window.localStorage.setItem("userInfo", JSON.stringify(res));
            resolve(res);
          });
        }
      });
    },
    getUsersList({ commit }) {
      http.post("/user/getAllUser", { type: 1 }).then(({ data }) => {
        data.forEach((item) => {
          item.label = item.name;
          item.value = item.userId;
        });
        commit("setUsersList", data);
      });
    },
    getOrganizationData({ commit }) {
      http.get("/dept/tree").then((res) => {
        fixData(res);
        commit("setOrganizationData", res);
      });
      function fixData(arr) {
        arr.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          if (item.child) {
            item.children = item.child;
            delete item.child;
            fixData(item.children);
          } else {
            delete item.child;
            item.children = null;
          }
        });
      }
    },
    getFullOrganizationData({ commit }) {
      http.post("/user/getAllUser", { type: 2 }).then(({ data }) => {
        fixData([data[0]]);
        commit("setFullOrganizationData", data[0]);
      });
      function fixData(arr) {
        arr.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          if (item.children.length) {
            fixData(item.children);
          }
        });
      }
    },
    getLevelDeptData({ commit }) {
      let promiseArr = ["FIRST_DEPT", "SECOND_DEPT", "THIRD_DEPT"];
      let result = [
        {
          id: "dept",
          name: "一级部门",
          children: [],
        },
        {
          id: "dept",
          name: "二级部门",
          children: [],
        },
        {
          id: "dept",
          name: "三级部门",
          children: [],
        },
      ];
      Promise.all(
        promiseArr.map((pCode) =>
          http.get("/dictSysItem/deptList", {
            params: {
              pCode,
            },
          }),
        ),
      ).then((res) => {
        result.forEach((item, index) => {
          item.children = res[index];
          item.children.forEach((i) => {
            i.label = i.name;
            i.value = i.id;
          });
        });
        commit("setLevelDeptData", result);
      });
    },
  },
});
