<template>
  <div class="login">
    <div class="login__header">
      <img src="@/assets/images/login__logo--tip.png" alt="" class="login__header-logo" />
    </div>
    <div class="login__body">
      <div class="login__body-tip">
        <div class="login__body-tip--main">学习客户，服务客户</div>
        <div class="login__body-tip--sub">
          以客户为中心，以产品力和质量为中心，以奋斗者为中心，以快乐为中心，不断变化，进化。
        </div>
      </div>
      <div class="login__body-box">
        <!-- 登录页面 -->
        <div class="login__page-login" v-show="page === 'login'">
          <div
            class="qr-code-btn"
            :class="[loginMethods === 'qrcode' ? 'qr-code-bg' : '']"
            @click="toggleQrcode"
          >
            <div class="tip">{{ loginMethods === "qrcode" ? "账号登录" : "扫码登录" }}</div>
          </div>
          <div class="login-wrapper-unqrcode" v-show="loginMethods !== 'qrcode'">
            <h2>欢迎登录</h2>
            <el-tabs v-model="loginMethods" @keyup.enter="loginIn">
              <el-tab-pane
                v-for="item in loginMethodsOptions"
                :key="item.value"
                :label="item.label"
                :name="item.value"
              ></el-tab-pane>
            </el-tabs>
            <el-form ref="loginForm" :model="formData" :rules="rules">
              <el-form-item v-for="item in loginActive" :key="item.key" :prop="item.key">
                <el-input
                  v-model="formData[item.key]"
                  :placeholder="item.placeholder"
                  :show-password="item.isPasword"
                >
                  <i slot="prefix" :class="item.icon">
                    <span class="line"></span>
                  </i>
                  <div
                    slot="suffix"
                    v-if="item.isCaptcha"
                    class="captcha-btn"
                    :class="[item.tip === '获取验证码' ? '' : 'disabled']"
                    @click="getCaptchaForLogin"
                  >
                    {{ item.tip }}
                  </div>
                </el-input>
              </el-form-item>
            </el-form>

            <div class="password-control">
              <el-checkbox
                v-model="formData.rememberPassword"
                v-show="['account', 'jobNumber'].includes(this.loginMethods)"
              >
                记住密码
              </el-checkbox>
              <div class="forget-password" @click="toggleLoginOrResetPassword('resetPassword')">
                忘记密码
              </div>
            </div>
            <el-button class="submit-btn" type="primary" @click="loginIn" :loading="loading">
              登录
            </el-button>
          </div>
          <div class="login-wrapper-qrcode" v-show="loginMethods === 'qrcode'">
            <div class="wrapper">
              <h2>扫码登录</h2>
              <div class="sub-tip">请使用钉钉移动端扫码二维码</div>
              <div class="code-wrapper" id="dingTalkQRcode"></div>
            </div>
          </div>
        </div>

        <!-- 企业列表页面 -->
        <div class="login__page-company" v-show="page === 'companyList'">
          <h2>登录成功！</h2>
          <div class="company-tip" v-if="companyLoading">正在获取企业或组织信息...</div>
          <div v-else class="company-list">
            <div class="explain">
              <b>{{ currentAccount }}</b>
              在以下企业或组织绑定了账号，已为您自动选择了第一家企业或组织。
            </div>
            <div class="company" v-for="item in companyList" :key="item.value">
              <div class="icon" :class="item.logo ? 'text-bg' : ''">
                <img width="40" height="40" v-if="item.logo" :src="item.logo" />
                <span v-else>{{ item.label.slice(0, 1) }}</span>
              </div>
              <div class="company-info">
                <div class="company-name">
                  {{ item.label }}
                </div>
                <div class="user-name">
                  {{ item.name }}{{ item.enName ? `(${item.enName})` : "" }}
                </div>
              </div>
              <i class="icon-main-tiaozhuan"></i>
            </div>
          </div>
          <div v-if="userInfoLoading">正在初始化用户数据...</div>
          <div class="logout">
            <el-button
              :disabled="logoutLoading"
              :loading="safeLogoutLoading"
              type="primary"
              @click="logoutSafely"
            >
              安全退出
            </el-button>
            <el-button
              type="primary"
              :disabled="safeLogoutLoading"
              :loading="logoutLoading"
              @click="logout(false, false, false)"
            >
              退出登录
            </el-button>
          </div>
        </div>

        <!-- 重置密码页面 -->
        <div class="login__page-resetpassword" v-show="page === 'resetPassword'">
          <div
            class="back"
            :class="[resetPasswordLoading ? 'disabled' : '']"
            @click="toggleLoginOrResetPassword('login')"
          >
            <i class="icon-main-zuofan"></i>
            <span>返回</span>
          </div>
          <h2>重置密码</h2>
          <el-form
            ref="resetPasswordForm"
            :model="resetPasswordFormData"
            :rules="resetPasswordRules"
          >
            <el-form-item v-for="item in resetPasswordActive" :key="item.key" :prop="item.key">
              <el-input
                v-model="resetPasswordFormData[item.key]"
                :placeholder="item.placeholder"
                :show-password="item.isPasword"
              >
                <i slot="prefix" :class="item.icon">
                  <span class="line"></span>
                </i>
                <div
                  slot="suffix"
                  v-if="item.isCaptcha"
                  class="captcha-btn"
                  :class="[item.tip === '获取验证码' ? '' : 'disabled']"
                  @click="getCaptchaForResetPassword"
                >
                  {{ item.tip }}
                </div>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button
            class="submit-btn"
            type="primary"
            :loading="resetPasswordLoading"
            @click="resetPassword"
          >
            确认
          </el-button>
        </div>
      </div>
    </div>
    <div class="login__footer">
      <p>上海法辰电子科技有限公司</p>
      <p>
        <span style="margin-right: 25px">联系方式：+86-21-59830621</span>
        <span>联系地址：上海青浦区明珠路1018弄A401</span>
      </p>
      <p>
        Copyright©2020 版权所有 Powered by hbtesaas.com
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          ( 沪ICP备15039076号-3 )
        </a>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011802004918"
          target="_blank"
        >
          ( 沪公网安备31011802004918号 )
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import dingTalkQRCode from "@/assets/js/dingTalkQRCode"; // 钉钉二维码预留
import { cloneDeep } from "lodash";
import { mainMethods } from "@/assets/js/globalUtils.js";

export default {
  data() {
    return {
      loading: false,
      logoutLoading: false,
      safeLogoutLoading: false,
      resetPasswordLoading: false,
      companyLoading: false,
      userInfoLoading: false,
      page: "login", //页面（登录，企业列表，重置密码）
      loginMethods: "account", //记录用户上一次登录方式（账号，工号，验证码，二维码）
      loginMethodsOptions: [
        {
          label: "账号",
          value: "account",
        },
        {
          label: "工号",
          value: "jobNumber",
        },
        {
          label: "验证码",
          value: "captcha",
        },
      ],
      formData: {
        username: "",
        pwd: "",
        orgCode: "",
        workNo: "",
        pwd: "",
        mobile: "",
        smsCode: "",
        rememberPassword: false,
      },
      resetPasswordFormData: {
        phone: "",
        code: "",
        pwd: "",
        confirmPwd: "",
      },
      activeMap: [
        //输入控件映射数据
        {
          icon: "icon-main-a-renyuanrenyuanguanligerenzhongxin",
          key: "username",
          placeholder: "请输入账号",
          isCaptcha: false,
          isPasword: false,
          loginMethods: "account",
        },
        {
          icon: "icon-main-mima",
          key: "password",
          placeholder: "请输入密码",
          isCaptcha: false,
          isPasword: true,
          loginMethods: "account",
        },
        {
          icon: "icon-main-a-caigougongsigongsi",
          key: "orgCode",
          placeholder: "请输入公司组织代码",
          isCaptcha: false,
          isPasword: false,
          loginMethods: "jobNumber",
        },
        {
          icon: "icon-main-gongpai",
          key: "workNo",
          placeholder: "请输入工号",
          isCaptcha: false,
          isPasword: false,
          loginMethods: "jobNumber",
        },
        {
          icon: "icon-main-mima",
          key: "pwd",
          placeholder: "请输入密码",
          isCaptcha: false,
          isPasword: true,
          loginMethods: "jobNumber",
        },
        {
          icon: "icon-main-shouji",
          key: "mobile",
          placeholder: "请输手机号",
          isCaptcha: false,
          isPasword: false,
          loginMethods: "captcha",
        },
        {
          icon: "icon-main-shouji",
          key: "smsCode",
          placeholder: "请输验证码",
          isCaptcha: true,
          isPasword: false,
          loginMethods: "captcha",
          tip: "获取验证码",
          timer: null,
        },
        {
          icon: "icon-main-shouji",
          key: "phone",
          placeholder: "请输手机号",
          isCaptcha: false,
          isPasword: false,
          loginMethods: "resetPassword",
        },
        {
          icon: "icon-main-yanzhengma",
          key: "code",
          placeholder: "请输验证码",
          isCaptcha: true,
          isPasword: false,
          loginMethods: "resetPassword",
          tip: "获取验证码",
          timer: null,
        },
        {
          icon: "icon-main-mima",
          key: "pwd",
          placeholder: "请输入密码",
          isCaptcha: false,
          isPasword: true,
          loginMethods: "resetPassword",
        },
        {
          icon: "icon-main-mima",
          key: "confirmPwd",
          placeholder: "请再次输入密码",
          isCaptcha: false,
          isPasword: true,
          loginMethods: "resetPassword",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getCompanyList", "getUserInfo"]),
    ...mapMutations(["setUserInfo", "setCompanyList", "setActiveCompany", "setAuthorization"]),
    fillAccountCache() {
      let label = window.btoa("acountInfo");
      let value = window.localStorage.getItem(label);
      if (value) {
        this.formData = JSON.parse(window.atob(value.slice(1, -1)));
      }
    },
    toggleQrcode() {
      this.loginMethods = this.loginMethods === "qrcode" ? "account" : "qrcode";
    },
    loginIn() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let { username, password, pwd, workNo, orgCode, mobile, smsCode, rememberPassword } =
            this.formData;
          let requestConfig = {
            account: {
              api: "/login",
              params: {
                username,
                password: this.$md5(password || ""), //md5不能传undefind
              },
            },
            jobNumber: {
              api: "/workLogin",
              params: { pwd: this.$md5(pwd || ""), workNo, orgCode },
            },
            captcha: {
              api: "/mobileLogin",
              params: { mobile, smsCode },
            },
          };

          this.$http
            .post(requestConfig[this.loginMethods].api, requestConfig[this.loginMethods].params, {
              baseURL: "/auth",
            })
            .then((res) => {
              this.setAuthorization(res);
              let label = window.btoa("acountInfo");
              if (rememberPassword) {
                let value =
                  Math.floor(Math.random() * 10) +
                  window.btoa(JSON.stringify(this.formData)) +
                  Math.floor(Math.random() * 10);
                window.localStorage.setItem(label, value);
              } else {
                window.localStorage.removeItem(label);
              }
              this.loginSuccess();
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    loginSuccess() {
      this.companyLoading = true;
      this.page = "companyList";
      this.getCompanyList(true)
        .then((res) => {
          this.accessingSystem(res[0]);
        })
        .finally(() => {
          this.companyLoading = false;
        });
    },
    accessingSystem(company) {
      //记录当前应用的企业
      this.setActiveCompany(company);
      //选择企业，让后端标记后再请求用户数据
      this.userInfoLoading = true;
      this.$http.post("/enterpriseUser/select", { enterpriseCode: company.value }).then(() => {
        this.getUserInfo(true)
          .then((res) => {
            let value = JSON.parse(window.localStorage.getItem("lastExitedPage"));
            if (value && res.userId == value.userId && value.path) {
              this.$router.push(value.path);
              window.localStorage.removeItem("lastExitedPage");
            } else {
              this.$router.push("/");
            }
          })
          .finally(() => {
            this.userInfoLoading = false;
          });
      });
    },
    toggleLoginOrResetPassword(page) {
      let formNode = page === "login" ? "resetPasswordForm" : "loginForm";
      this.$refs[formNode].clearValidate();
      this.page = page;
    },
    logout(safe, notoken, saveFullpath) {
      if (safe) {
        this.safeLogoutLoading = true;
      } else {
        this.logoutLoading = true;
      }
      mainMethods.logout(safe, notoken, saveFullpath).then(() => {
        this.page = "login";
        this.safeLogoutLoading = this.logoutLoading = false;
      });
    },
    getCaptchaForLogin() {
      //校验手机号
      this.$http.get("/msg/phone/send/login?phone=" + this.formData.mobile).then((res) => {
        let target = this.activeMap.find(({ key }) => key === "smsCode");
        let num = 60;
        target.tip = num + "s";
        target.timer = setInterval(() => {
          --num;
          target.tip = num + "s";
          if (!num) {
            clearInterval(target.timer);
            target.tip = "获取验证码";
          }
        }, 1000);
      });
    },
    getCaptchaForResetPassword() {
      //校验手机号
      this.$http
        .get("/msg/phone/send/noPwd?phone=" + this.resetPasswordFormData.phone)
        .then((res) => {
          let target = this.activeMap.find(({ key }) => key === "code");
          let num = 60;
          target.tip = num + "s";
          target.timer = setInterval(() => {
            --num;
            target.tip = num + "s";
            if (!num) {
              clearInterval(target.timer);
              target.tip = "获取验证码";
            }
          }, 1000);
        });
    },
    resetPassword() {
      let { phone, code, pwd, confirmPwd } = { ...this.resetPasswordFormData };
      let params = {
        phone,
        code,
        pwd: this.$md5(pwd),
        confirmPwd: this.$md5(confirmPwd),
      };
      this.$refs.resetPasswordForm.validate((valid) => {
        if (valid) {
          this.resetPasswordLoading = true;
          this.$http
            .post("/user/updatePwdByPhone", params)
            .then((res) => {
              this.$message.success("操作成功");
              this.resetPasswordFormData = {
                phone: "",
                code: "",
                pwd: "",
                confirmPwd: "",
              };
              this.page = "login";
              this.loginMethods = "account";
            })
            .finally(() => {
              this.resetPasswordLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    logoutSafely() {
      this.formData = {
        username: "",
        pwd: "",
        orgCode: "",
        workNo: "",
        pwd: "",
        mobile: "",
        smsCode: "",
        rememberPassword: false,
      };
      this.logout(true, false, false);
      this.$nextTick(() => {
        this.$refs.loginForm?.clearValidate();
      });
    },
  },
  computed: {
    ...mapState(["companyList"]),
    loginActive() {
      return this.activeMap.filter(({ loginMethods }) => loginMethods === this.loginMethods);
    },
    resetPasswordActive() {
      return this.activeMap.filter(({ loginMethods }) => loginMethods === "resetPassword");
    },
    currentAccount() {
      if (this.loginMethods === "account") {
        return this.formData.username;
      } else if (this.loginMethods === "jobNumber") {
        return this.formData.workNo;
      } else if (this.loginMethods === "captcha") {
        return this.formData.mobile;
      } else {
        return "钉钉扫码用户";
      }
    },
    rules() {
      return {
        username: [
          {
            required: this.loginMethods === "account",
            message: "账号不能为空",
            trigger: ["change", "blur"],
          },
        ],
        pwd: [
          {
            required: this.loginMethods === "account",
            message: "密码不能为空",
            trigger: ["change", "blur"],
          },
          { min: 6, message: "密码长度不少于6位", trigger: ["change", "blur"] },
          { max: 20, message: "密码长度不大20位", trigger: ["change", "blur"] },
        ],
        orgCode: [
          {
            required: this.loginMethods === "jobNumber",
            message: "公司组织代码不能为空",
            trigger: ["change", "blur"],
          },
        ],
        workNo: [
          {
            required: this.loginMethods === "jobNumber",
            message: "工号不能为空",
            trigger: ["change", "blur"],
          },
        ],
        pwd: [
          {
            required: this.loginMethods === "jobNumber",
            message: "密码不能为空",
            trigger: ["change", "blur"],
          },
          { min: 6, message: "密码长度不少于6位", trigger: ["change", "blur"] },
          { max: 20, message: "密码长度不大20位", trigger: ["change", "blur"] },
        ],
        mobile: [
          {
            required: this.loginMethods === "captcha",
            message: "手机号不能为空",
            trigger: ["change", "blur"],
          },
        ],
        smsCode: [
          {
            required: this.loginMethods === "captcha",
            message: "验证码不能为空",
            trigger: ["change", "blur"],
          },
        ],
      };
    },
    resetPasswordRules() {
      return {
        phone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: ["change", "blur"],
          },
        ],
        code: [{ required: true, message: "验证码不能为空", trigger: ["change", "blur"] }],
        pwd: [{ required: true, message: "新密码不能为空", trigger: ["change", "blur"] }],
        confirmPwd: [
          { required: true, message: "二次确认密码不能为空", trigger: ["change", "blur"] },
        ],
      };
    },
  },
  created() {
    this.loginMethods = window.localStorage.getItem("prevLoginMethods") || "account";
    this.fillAccountCache();
    window.addEventListener("dingTalkLoginSuccess", this.loginSuccess);
  },
  mounted() {
    dingTalkQRCode();
  },
  destroyed() {
    window.removeEventListener("dingTalkLoginSuccess", this.loginSuccess);
  },
  watch: {
    loginMethods(to, from) {
      window.localStorage.setItem("prevLoginMethods", to);
    },
  },
};
</script>
<style lang="scss" scoped>
.back {
  margin-bottom: 20px;
  cursor: pointer;
}
h2 {
  margin-bottom: 10px;
}
.submit-btn {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  height: 40px;
}
.qr-code-btn {
  cursor: pointer;
  position: absolute;
  right: 3px;
  background: url("~@/assets/images/login__count--code.png");
  width: 64px;
  height: 64px;
  top: 3px;
  &.qr-code-bg {
    background: url("~@/assets/images/login__count.png");
  }
  &:hover .tip {
    display: block;
  }
  .tip {
    user-select: none;
    width: 102px;
    height: 54px;
    background: url("~@/assets/images/login__box-tip--tab.png");
    position: absolute;
    left: -102px;
    line-height: 47px;
    top: 5px;
    color: #fff;
    font-size: 14px;
    text-indent: 19px;
    display: none;
  }
}
.captcha-btn {
  position: absolute;
  right: 0px;
  top: 4px;
  width: 84px;
  height: 32px;
  line-height: 32px;
  background: #ecf3fe;
  color: #488af8;
  font-size: 14px;
  z-index: 1;
}
.login-wrapper-unqrcode {
  height: 444px;
  position: relative;
  .password-control {
    display: flex;
    justify-content: space-between;
    .forget-password {
      color: #488af8;
      cursor: pointer;
    }
  }
}
.login-wrapper-qrcode {
  text-align: center;
  margin-top: 50px;
}
.login__page-company {
  position: relative;
  height: 444px;
  .company-tip {
    margin: 20px 0;
  }
  .explain {
    margin: 20px 0;
  }
  .company {
    height: 68px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    margin-bottom: 15px;
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    .icon {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    .company-info {
      .company-name {
        font-size: 15px;
        font-weight: bolder;
        color: #111;
      }
      .user-name {
        font-size: 14px;
        color: #666;
      }
    }
    i {
      position: absolute;
      right: 15px;
      line-height: 40px;
    }
  }
  .logout {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    .el-button {
      height: 40px;
      flex: 1;
    }
  }
}

.login__page-resetpassword {
  height: 444px;
  position: relative;
}
.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  line-height: initial;
  padding: 40px 0px 30px;
  box-sizing: border-box;
  background: url("~@/assets/images/login__bg--left.png") no-repeat bottom left/48.5% auto,
    url("~@/assets/images/login__bg--right.png") no-repeat top right/23% auto, #f8f9fa;
  &__header {
    padding-left: 46px;
    &-logo {
      width: 310px;
    }
  }
  &__body {
    @media screen and (max-width: 1600px) {
      width: 1200px;
      margin-left: calc((100vw - 1200px) * 0.3);
      background-size: 50% auto;
    }
    @media screen and (min-width: 1601px) and (max-width: 1920px) {
      width: 1370px;
      margin-left: calc((100vw - 1370px) * 0.3);
      background-size: 53% auto;
    }
    @media screen and (min-width: 1921px) {
      width: 1670px;
      margin-left: calc((100vw - 1670px) * 0.3);
      background-size: 54% auto;
      height: 700px;
    }
    height: 540px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("~@/assets/images/login__bg--box.png") no-repeat bottom left;

    &-tip {
      align-self: flex-start;
      &--main {
        color: #2878ff;
        font-size: 38px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      &--sub {
        color: #666;
        font-size: 18px;
      }
    }
    &-box {
      width: 444px;
      height: 548px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #dbdbdb;
      position: relative;
      padding: 58px 32px 45px;
      ::v-deep .el-input__inner {
        padding-left: 52px !important;
        height: 40px;
      }
      ::v-deep .captcha-btn.disabled {
        pointer-events: none;
      }
      ::v-deep .is-active {
        color: #2878ff;
      }
      ::v-deep .el-tabs__header {
        margin-bottom: 30px;
        border: 0;
      }
      ::v-deep .el-tabs__active-bar {
        background: #2878ff;
      }
      ::v-deep .el-tabs__nav-wrap::after {
        background: none;
      }
      ::v-deep .el-input__prefix {
        color: #999;
        margin-left: 12px;
        .line {
          padding: 0 0.5px;
          background: #e5e5e5;
          margin-left: 10px;
        }
      }
      ::v-deep .el-input__prefix,
      ::v-deep .el-input__suffix {
        line-height: 40px;
      }
      ::v-deep .el-input__suffix {
        padding-right: 15px;
        cursor: pointer;
      }
      &--acount {
        height: 100%;
        .tip {
          font-size: 22px;
          color: #111;
          font-weight: bold;
          margin-bottom: 18px;
        }
        ::v-deep .acount {
          margin-bottom: 30px;
        }
        ::v-deep .password {
          margin-bottom: 32px;
        }
        .smsCode {
          margin-bottom: 32px;
        }
        .getSmsCode-btn {
          position: relative;
          width: 69px;
          height: 100%;
          .btn,
          .smsCode-time {
            position: absolute;
            top: 4px;
            left: 0;
            width: 84px;
            height: 32px;
            background: #ecf3fe;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #488af8;
            font-size: 14px;
            z-index: 1;
          }
          .smsCode-time {
            z-index: 2;
            cursor: auto;
          }
        }
        ::v-deep .el-icon-view {
          display: none;
        }
        .password-operation {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 110px;
        }
        .save-password {
          ::v-deep .el-checkbox__label {
            color: #999;
          }
        }
        .readFile {
          margin-bottom: 20px;
          ::v-deep .el-checkbox__label {
            color: #999;
            span {
              color: #488af8;
            }
          }
        }
        .send-button {
          position: absolute;
          bottom: 45px;
          height: 40px;
          display: block;
          width: 380px;
          margin-top: 22px;
          background: #2878ff !important;
        }
      }
      &--tab {
        position: absolute;
        top: 5px;
        right: 5px;
        background: #ccc;
        width: 64px;
        height: 64px;
        cursor: pointer;
        background: url("~@/assets/images/login__count.png") no-repeat;
        user-select: none;
        .tip {
          color: #fff;
          font-size: 14px;
          width: 90px;
          height: 40px;
          background: url("~@/assets/images/login__box-tip--tab.png") no-repeat;
          background-size: 90px 40px;
          position: absolute;
          top: 10px;
          left: -90px;
          line-height: 36px;
          text-indent: 13px;
        }
        &.code {
          background: url("~@/assets/images/login__count--code.png") no-repeat;
        }
      }
      &--code {
        margin-top: -30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .wrapper {
          height: 287px;
          .tip {
            color: #111;
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .sub-tip {
            color: #2c2d2f;
            font-size: 14px;
            margin-bottom: 38px;
          }
          .code-wrapper {
            width: 210px;
            height: 230px;
            background: #ccc;
            margin-top: -40px;
          }
        }
      }
      .select-enterprise {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        padding: 25px 32px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-title {
          color: #111;
          font-size: 22px;
          font-weight: bold;
          line-height: 22px;
          margin-bottom: 28px;
        }
        &-hint {
          color: #444;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 21px;
          span {
            color: #111;
            font-weight: bold;
          }
        }
        &-scroll {
          width: 100%;
          flex: 1;
          overflow-y: scroll;
          .nodata {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
              margin-top: 50px;
              margin-bottom: 50px;
            }
            p {
              font-size: 14px;
            }
          }
          .enterprise-item {
            width: 100%;
            height: 68px;
            background: #ffffff;
            border: 1px solid #dbdbdb;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 0 21px 0 16px;
            box-sizing: border-box;
            margin-bottom: 16px;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0;
            }
            .icon {
              width: 40px;
              height: 40px;
              background: #488af8;
              border-radius: 6px;
              flex-shrink: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 3px;
              box-sizing: border-box;
              img {
                width: 100%;
              }
              span {
                font-size: 20px;
                color: #fff;
                font-weight: bold;
                line-height: 20px;
              }
            }
            .icon.bgc {
              background-color: #fff;
              padding: 0;
              border: 1px solid #eee;
            }
            .enterprise {
              flex: 1;
              display: flex;
              flex-direction: column;
              padding: 0 16px;
              box-sizing: border-box;
              .enterprise-title {
                font-size: 16px;
                font-weight: bold;
                color: #111;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .user-name {
                font-size: 14px;
                color: #666;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            i {
              flex-shrink: 0;
              font-size: 13px;
              color: #444;
            }
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .back-login {
        color: #444;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 21px;
        cursor: pointer;
        .icon-main-tiaozhuan {
          display: inline-block;
          transform: rotateY(180deg);
        }
      }
      .reset-success {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        .success-circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 123px;
          height: 123px;
          background: rgba(40, 120, 255, 0.1);
          border-radius: 50%;
          margin-bottom: 45px;
        }
        &-text {
          font-size: 22px;
          color: #111;
          margin-bottom: 26px;
        }
        .icon-success {
          font-size: 60px;
          color: #2878ff;
        }
        .success-desc {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
  &__footer {
    font-size: 12px;
    color: #666;
    text-align: center;
  }
}
</style>
<style lang="scss">
</style>